import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <a class="skip-to-content-link" href="#about-book">
      Skip to content
    </a>
    <div className={"container"}>
      <div className={"top-menu"}>
        <div className={"logo"}></div>

        <div className={"get-started"}>
          <a href="#call-to-action">Order Now</a>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
